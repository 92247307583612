import "../css/footer.css";
import { SlSocialFacebook, SlSocialInstagram, SlSocialLinkedin, SlSocialYoutube } from "react-icons/sl";

function FooterBar() {
  return (
    <div className="footer-container">
      <div className="footer-icon">
        <a href="https://www.facebook.com/nofameagency/" target="_blank" rel="noopener noreferrer">
          <SlSocialFacebook />
        </a>
        <a href="https://www.instagram.com/nofame_agency/" target="_blank" rel="noopener noreferrer">
          <SlSocialInstagram />
        </a>
        <a href="https://www.youtube.com/@NoFameAgency" target="_blank" rel="noopener noreferrer">
          <SlSocialYoutube />
        </a>
        <a href="https://www.linkedin.com/company/nofameagency/" target="_blank" rel="noopener noreferrer">
          <SlSocialLinkedin />
        </a>
      </div>

      <div className="footer-copyright">
        <div className="privacy">
          <p onClick={() => window.location.href = "https://policies.google.com/privacy"}>Privacy Policy</p><label>•</label>
          <p onClick={() => window.location.href = "https://policies.google.com/terms"}>Terms of Service</p>
        </div>

        <p>No Fame GmbH copyright © 2025. All rights reserved.</p>
      </div>
    </div>
  );
}

export default FooterBar;
