import "../css/contact.css";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, Link } from "react-router-dom";

function Contact() {
  const navigate = useNavigate(); 
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const pageVariants = {
    initial: { x: "100%", opacity: 0 },
    animate: { x: 0, opacity: 1, transition: { duration: 0.8 } },
    exit: { x: "-100%", opacity: 0, transition: { duration: 0.8 } },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div variants={pageVariants} initial="initial" animate="animate" exit="exit" className="contact-page">
      <div className="contact-content">
        <div className="contact-form-2">
          <Link to="/">
            <img src="/logo/icon_arrow.ico" width={40} />
          </Link>
          <div className="contact-text-first">
            <h2>Let's Create Together</h2>
          </div>
          <br />

          <div className="contact-form-container">

            <form action="https://formsubmit.co/chularat@nofame.agency" method="POST" className="contact-form-2">
              <div className="form-group-2">
                <label style={{ display: "flex" }}>
                  Name <label style={{ color: "#871212" }}>*</label>
                </label>
                <input type="text" name="name" required />
              </div>

              <div className="form-group-2">
                <label style={{ display: "flex" }}>
                  Email <label style={{ color: "#871212" }}>*</label>
                </label>
                <input type="email" name="email" required />
              </div>

              <div className="form-group-2">
                <label style={{ display: "flex" }}>
                  Message <label style={{ color: "#871212" }}>*</label>
                </label>
                <textarea name="message" rows="5" required></textarea>
              </div>

              <div className="form-group">
                <ReCAPTCHA sitekey="6LdNw_EqAAAAAMqVjkgT9P3Qbs2VSsCNKFQ_lrQh" onChange={handleCaptchaChange} />
              </div>
              <br />

              <button type="submit" className="send-button">— SEND —</button>
            </form>
          </div>


        </div>
      </div>
    </motion.div>
  );
}

export default Contact;
