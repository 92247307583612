import { Modal } from "antd";
import "../../css/blog.css"
import { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";


function DonationFeb2025() {
  const pageVariants = {
    initial: { y: "100%", opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { duration: 0.8 } },
    exit: { y: "-100%", opacity: 0, transition: { duration: 0.8 } },
  }
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModalLetter = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit" className="Blog-sub">

      <div className="text-sub-blog">
        <div className="blog-sub-header">

          <h1><Link to="/">
            <img src="/logo/icon_arrow.ico" width={20} />
          </Link>Gives Back to the Community</h1>
        </div>

        <h3 style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <p>NF Entertainment Thai Team</p>
          <p>6 February 2025</p>
        </h3>

        <h2>
          At NF Entertainment, we believe in using our platform not only to create unforgettable experiences but also to make a meaningful impact on the communities around us. We recognize the importance of supporting those in need and fostering positive change wherever we can. With this commitment in mind, our Thai team has come together to extend a helping hand to underprivileged children by donating toys and decorative props from Paradiso Carnival to the Foundation For Slum Child Care.

          These imaginative and colorful props were carefully selected to brighten up the children’s environment, creating a more engaging and inspiring space for them to learn and play. However, we knew that a joyful atmosphere alone was not enough. To further support their well-being, we also contributed essential food supplies, including wet food, eggs, and juice, ensuring they have the nutrition necessary for a healthier and happier life.
        </h2>
        <h2>
          Our goal is simple: to give back and help create opportunities for young children to grow and thrive in a nurturing environment. We understand that a child's surroundings play a crucial role in their development, shaping their sense of security, creativity, and potential. Through this small act of kindness, we hope to bring joy and inspiration to these young minds, encouraging them to dream big and believe in a brighter future.
        </h2>
        <h2>
          This initiative is just one part of our broader commitment to social responsibility. At NF Entertainment, we believe that businesses have the power to drive meaningful change, and we are dedicated to making a lasting impact on the communities that support us. Whether through donations, volunteering, or other outreach programs, we are continuously exploring ways to extend our support to those in need.
          We are deeply honored to have received a heartfelt thank-you letter from the Foundation For Slum Child Care, acknowledging our efforts. This recognition fuels our passion to continue making a difference and reminds us of the importance of giving back.
        </h2>
      </div>
      <div
        className="TY"
      >
        <h2>
          As we continue to grow, we remain committed to finding new ways to contribute to society and uplift those who need it most. Together, we can build a world where entertainment, creativity, and compassion go hand in hand—because making a difference is just as important as creating unforgettable experiences.
        </h2>
        <h2>
          We are honored to have received a heartfelt
          {" "}<a onClick={showModalLetter} >
            thank-you letter
          </a>{" "} from the Foundation For Slum Child Care in appreciation of our donation of toys, decorative props, and essential food supplies. This recognition reinforces our commitment to giving back and supporting underprivileged children in meaningful ways.
        </h2>
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel} footer={null} centered>
        <img
          src="/home/commu/TY.jpeg"
          alt="Thank You Letter"
          style={{ width: "100%", height: "auto" }}
        />
      </Modal>
    </motion.div>
  )
}
export default DonationFeb2025;
