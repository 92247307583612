import { Image } from "antd";
import { Link } from "react-router-dom";

const images = [
    "/home/photo/nofame-photo-main (1).webp",
    "/home/photo/nofame-photo-main (2).webp",
    "/home/photo/nofame-photo-main (3).webp",
    "/home/photo/nofame-photo-main (4).webp",
    "/home/photo/nofame-photo-main (5).webp",
    "/home/photo/nofame-photo-main (6).webp",
    "/home/photo/nofame-photo-main (7).webp",
    "/home/photo/nofame-photo-main (8).webp",
    "/home/photo/nofame-photo-main (9).webp",
    "/home/photo/nofame-photo-main (10).webp",
    "/home/photo/nofame-photo-main (11).webp",
    "/home/photo/nofame-photo-main (12).webp",
    "/home/photo/nofame-photo-main (13).webp",
    ...Array.from({ length: 108 }, (_, i) => `/Photography/nofame-photo (${i + 1}).webp`)
];

function Photo() {

    return (
        <div
            initial="initial"
            animate="animate"
            exit="exit"
            className="photo-container"
        >
            <div>
                <Link to="/">
                    <img src="/logo/icon_arrow.ico" width={40} />
                </Link>

                <div className="gallery-header">
                    <h1 style={{ textAlign: "center", color: "white" }}>Photography</h1>
                    <p>Commercial • Event • Travel • Creative Concepts & Styling  </p>
                </div>
            </div>

            <div className="masonry-grid">
                <Image.PreviewGroup>
                    {images.map((src, index) => (
                        <div className="masonry-item" key={index}>
                            <Image
                                src={src}
                                alt={`No Fame Photo ${index + 1}`}
                                className="gallery-image"
                                preview
                            />
                        </div>
                    ))}
                </Image.PreviewGroup>
            </div>
        </div>
    );
}

export default Photo;
