import React, { useEffect, useState, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import { IoPlayCircle } from "react-icons/io5";
import { Modal } from "antd";

const videos = [
    { src: "/home/videology/mazing_thailand.mp4", description: "Amazing Thailand Video" },
    { src: "/home/videology/neon_countdown.mp4", description: "S2O Bangkok Music Festival" },
    { src: "/home/videology/s2o.mp4", description: "Neon Countdown" },
];


function Video() {
    const [thumbnails, setThumbnails] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState("");
    const [currentDes, setCurrentDes] = useState("");

    const pageVariants = {
        initial: { y: "100%", opacity: 0 },
        animate: { y: 0, opacity: 1, transition: { duration: 0.8 } },
        exit: { y: "-100%", opacity: 0, transition: { duration: 0.8 } },
    }

    const handleOpenModal = (videoSrc, descriptionSrc) => {
        setCurrentVideo(videoSrc);
        setCurrentDes(descriptionSrc);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setCurrentVideo("");
        setCurrentDes("");
        setIsModalOpen(false);
    };

    useEffect(() => {
        videos.forEach((video) => {
            const videoElement = document.createElement("video");
            videoElement.src = video.src;
            videoElement.crossOrigin = "anonymous";
            videoElement.load();
            videoElement.onloadedmetadata = () => {
                const duration = videoElement.duration;
                videoElement.currentTime = duration / 2;
            };
            videoElement.onseeked = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                setThumbnails((prev) => ({
                    ...prev,
                    [video.src]: canvas.toDataURL("image/png")
                }));
            };
        });
    }, []);

    useEffect(() => {
        console.log("isModalOpen: ", isModalOpen);
        console.log("currentVideo: ", currentVideo);
    }, [isModalOpen, currentVideo]);


    return (
        <motion.div
            variants={pageVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="video-container"
        >
            <Link to="/">
                <img src="/logo/icon_arrow.ico" width={40} />
            </Link>
            <div className="playlist-header">
                <h1>Videography</h1>
            </div>

            {/* <div className="fullscreen-video-container">
                <iframe
                    src="https://player.vimeo.com/video/929781926?autoplay=1&muted=1&loop=1"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    title="Thailand || A Journey of Wonders (4K)"
                    style={{ width: "100vw", height: "100vh" }}
                ></iframe>
            </div> */}

            <div className="playlist-container">
                {videos.map((data, index) => (
                    <>
                        <div key={index} className="video-wrapper">
                            <img
                                src={thumbnails[data.src]}
                                alt="Video Thumbnail"
                                className="video-thumbnail"
                                onClick={() => handleOpenModal(data.src, data.description)}
                            />

                            <div className="play-icon" onClick={() => handleOpenModal(data.src, data.description)}>
                                <IoPlayCircle size={50} />
                            </div>

                            <div key={index}>
                                <p className="video-description">{data.description}</p>
                            </div>

                        </div>

                    </>
                ))}
            </div>


            <Modal
                open={isModalOpen}
                onCancel={handleCloseModal}
                footer={null}
                width="80vw"
                centered
                styles={{ body: { padding: 0, background: "black" } }}
            >
                {currentVideo ? (
                    <>
                        <video
                            key={currentVideo}
                            className="modal-video"
                            controls
                            autoPlay
                            style={{ width: "100%" }}
                        >
                            <source src={currentVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </>

                ) : (
                    <p>Loading...</p>
                )}
            </Modal>

        </motion.div>
    );
}

export default Video;
