import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Layout from "./Layout";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import {
  initGA,
  trackPageView,
  trackDevice,
  trackIP,
} from "./utils/analytics";
import Photo from "./pages/photo";
import Video from "./pages/Video";
import ScrollToTop from "./components/ScrollToTop";
import DonationFeb2025 from "./pages/commu/DonationFeb2025";

const GA_MEASUREMENT_ID = "G-1346X5NJ9F";

function AnimatedRoutes() {
  const location = useLocation();
  const isAuthPage = location.pathname === "/Photography" || location.pathname === "/Videography" || location.pathname === "/contact"  || location.pathname === "/blog/donation-2025";

  useEffect(() => {
    window.scrollTo(0, 0);
    initGA(GA_MEASUREMENT_ID);
    trackPageView(location.pathname + location.search);
    trackDevice();
    trackIP();

  }, [location.pathname]);

  return (
    <AnimatePresence mode="wait">
      {isAuthPage ? (
        <Routes location={location} key={location.pathname}>
          <Route path="/Photography" element={<Photo />} />
          <Route path="/Videography" element={<Video />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog/donation-2025" element={<DonationFeb2025 />} />
        </Routes>
      ) : (
        <Layout>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
          </Routes>
        </Layout>
      )}
    </AnimatePresence>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AnimatedRoutes />
    </Router>
  );
}

export default App;